<template>
  <div>
    <van-nav-bar
      :title="positionCity"
      left-text="返回"
      right-text="确定"
      left-arrow
      :fixed="fixed"
      @click-left="onClickBank"
      @click-right="onClickRight"
    />
    <div id="map">
      <input
        id="pickerInput"
        placeholder="输入关键字选取地点"
        style="position: absolute;
    top: 44px;
    left: 84px;
    z-index: 1000;
    margin-top: 10px;
    height: 31px;
    text-align: center;"
      />
    </div>
  </div>
</template>

<script>
import AMap from "AMap";
import AMapUI from "AMapUI";
import { NavBar } from "vant";
import { getLocation } from "@/service/Addfarmland.js";
export default {
  name: "Mymap",
  data() {
    return {
      icon: require("../../assets/image/marker/maker1.png"),
      lat: "",
      lng: "",
      marker: null,
      lnglat: "",
      fixed: true,
      positionCity: "正在定位中....",
      name: "",
      farmlandAddress: "",
      infoWindow: null
    };
  },
  components: {
    VanNavBar: NavBar
  },
  mounted() {
    this.init();
  },
  methods: {
    onClickBank() {
      this.$router.go(-1);
    },
    // 存储 经纬度 和城市名
    onClickRight() {
      this.$store.commit("setLng", this.lng);
      this.$store.commit("setLat", this.lat);
      this.$store.commit("setname", this.name);
        this.$router.push({
          path: `/addEnclosure`
        });
      },
    init() {
      var that = this;
      that.map = new AMap.Map("map", {
        zoom: 11,
        resizeEnable: true
      });
      // 定位
      AMap.plugin(["AMap.Geolocation", "AMap.Geocoder"], function() {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true,
          timeout: 2000,
          zoomToAccuracy: true,
          buttonPosition: "RB",
          buttonOffset: new AMap.Pixel(10, 20)
        });

        var geocoder = new AMap.Geocoder();
        geolocation.getCurrentPosition();
        that.infoWindow = new AMap.InfoWindow({
          offset: new AMap.Pixel(0, -34)
        });
        AMap.event.addListener(geolocation, "complete", e => {
          that.lat = e.position.lat;
          that.lng = e.position.lng;
          that.marker = new AMap.Marker({
            size: new AMap.Size(40, 50),
            position: e.position,
            icon: that.icon
          });
          that.map.add(that.marker);
          var positions = that.lng + "," + that.lat;
          getLocation(positions).then(res => {
            var province = res.data.province;
            var city = res.data.city;
            var county = res.data.county;
            that.positionCity = province + city + county;
          });
          regeoCode(positions);

          that.map.on("click", showInfoClick);
          function showInfoClick(e) {
            that.map.remove(that.marker);
            that.infoWindow.setContent(`<div>${null}</div>`);
            that.infoWindow.open(that.map, that.marker.getPosition());
            that.map.setCenter(that.marker.getPosition());
            that.lat = e.lnglat.lat;
            that.lng = e.lnglat.lng;
            that.lnglat = e.lnglat;
            if (that.marker) {
              that.map.remove(that.marker);
            }
            that.addMaker(that.lnglat);
            regeoCode(that.lnglat);
          }
        });
        AMap.event.addListener(geolocation, "error", e => {
          that.marker = new AMap.Marker({
            size: new AMap.Size(40, 50),
            position: e.position,
            icon: that.icon
          });
          that.map.add(that.marker);
          var lat = 39.904989;
          var lng = 116.405285;
          var positions = lng + "," + lat;
          getLocation(positions).then(res => {
            var province = res.data.province;
            var city = res.data.city;
            var county = res.data.county;
            that.positionCity = province + city + county;
          });
          regeoCode(positions);
          that.map.on("click", showInfoClick);
          function showInfoClick(e) {
            that.map.remove(that.marker);
            that.lat = e.lnglat.lat;
            that.lng = e.lnglat.lng;
            that.lnglat = e.lnglat;
            if (that.marker) {
              that.map.remove(that.marker);
            }
            that.addMaker(that.lnglat);
            regeoCode(that.lnglat);
          }
        });
        // 地理编码逆编译
        function regeoCode(lnglat) {
          geocoder.getAddress(lnglat, function(status, result) {
            if (status === "complete" && result.regeocode) {
              that.name = result.regeocode.formattedAddress;
              that.farmlandAddress =
                result.regeocode.addressComponent.township +
                result.regeocode.addressComponent.street +
                result.regeocode.addressComponent.streetNumber;
            }
            that.infoWindow.setContent(
              `<div>${that.name}</div><div>${that.farmlandAddress}</div>`
            );
            that.infoWindow.open(that.map, that.marker.getPosition());
            that.map.setCenter(that.marker.getPosition());
          });
        }
      });
      // 查询打maker
      AMapUI.loadUI(["misc/PoiPicker"], function(PoiPicker) {
        var poiPicker = new PoiPicker({
          input: "pickerInput"
        });
        poiPickerReady(poiPicker);
      });

      function poiPickerReady(poiPicker) {
        window.poiPicker = poiPicker;

        that.marker = new AMap.Marker();

        // 选取了某个POI
        poiPicker.on("poiPicked", function(poiResult) {
          var source = poiResult.source;
          var poi = poiResult.item;
          var info = {
            source: source,
            id: poi.id,
            name: poi.name,
            location: poi.location.toString(),
            address: poi.address
          };
          that.map.remove(that.marker);
          that.positionCity = info.name;
          that.name = info.name;
          that.lng = info.location.slice(0, 9);
          that.lat = info.location.slice(11);
          that.marker.setMap(that.map);
          that.infoWindow.setMap(that.map);

          that.marker.setPosition(poi.location);
          that.infoWindow.setPosition(poi.location);
          that.infoWindow.setContent(
            `<div>${info.name}</div><div>${info.address}</div>`
          );
          that.infoWindow.open(that.map, that.marker.getPosition());

          that.map.setCenter(that.marker.getPosition());
        });

        poiPicker.onCityReady(function() {
          poiPicker.suggest("");
        });
      }
    },
    // 添加maker
    addMaker(lnglat) {
      if (this.marker) {
        this.map.remove(this.marker);
        this.marker.setMap(null);
      }
      this.marker = new AMap.Marker({
        size: new AMap.Size(40, 50),
        position: lnglat,
        icon: this.icon
      });
      this.map.add(this.marker);
      var position = lnglat.lng + "," + lnglat.lat;
      getLocation(position).then(res => {
        var province = res.data.province;
        var city = res.data.city;
        var county = res.data.county;
        this.positionCity = province + city + county;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  height: 1000px;
}
</style>
